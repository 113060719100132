<template>
  <div class="main">
    <div class="box  center mb48 mR50">
      <div class="search box center">
        <span class="sp">设置状态</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search box center">
        <span class="sp">设备编号</span>
        <el-input v-model="searchTxt" placeholder="请输入测点编号"></el-input>
        <el-button @click="changeValue" class="mlbtn">查询</el-button>
      </div>
    </div>
    <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%">
      <el-table-column align="center" prop="target.code" label="测点编号" class="fs14"></el-table-column>
      <el-table-column align="center" prop="target.typeName" label="监测指标"></el-table-column>
      <el-table-column align="center" prop="target.staticDynamicSelected" label="数据类型">
        <template slot-scope="scope">{{ scope.row.target.staticDynamicSelected | staticDynamic }}</template>
      </el-table-column>
      <!-- <el-table-column prop="patchSelected" label="缺失数据补齐">
                 <template slot-scope="scope">{{scope.row.patchSelected | trueFalse}}</template>
      </el-table-column>
      <el-table-column prop="outlierSelected" label="异常数据去除">
               <template slot-scope="scope">{{scope.row.outlierSelected | trueFalse}}</template>
      </el-table-column>
      <el-table-column prop="denoisingSelected" label="消噪处理">
                 <template slot-scope="scope">{{scope.row.denoisingSelected | trueFalse}}</template>
      </el-table-column> -->
      <el-table-column align="center" prop="baselineSelected" label="基线校正">
        <template slot-scope="scope">{{ scope.row.baselineSelected | trueFalse }}</template>
      </el-table-column>
      <el-table-column align="center" prop="staticDynamicSeparationSelected" label="动静分离">
        <template slot-scope="scope">{{ scope.row.staticDynamicSeparationSelected | trueFalse }}</template>
      </el-table-column>
      <el-table-column align="center" prop="id" label="操作">
        <template slot-scope="scope">
          <span class="operate-txt" @click="go(scope.row.target.id)" :class="!isTrue ? 'disable_icon' : ''">设置</span>
        </template>
      </el-table-column>
      <!-- <template slot="empty">
        <span class="iconfont iconzanwushuju-"></span>
        <span>暂无数据</span>
      </template> -->
      <template slot="empty" class="imgBox">
        <div>
          <img src="../../assets/images/noData.png" alt="" class="imgver">
          <span>暂无数据</span>
        </div>
      </template>
    </el-table>
    <common-pagination v-show="tableData.length > 0" class="pageMain" :total="total" :currentPage="currentPage"
      @handleCurrentChange="handleCurrentChange" />

  </div>
</template>

<script>
import CommonPagination from '../../components/Pagination'
import upload from '@/assets/js/upload.js'
export default {
  name: "documentList",
  components: {
    CommonPagination
  },
  data() {
    return {
      loading: '',
      projectId: '',
      status: '',    //1:已处理，2:未处理
      searchTxt: '', //测点编号
      options: [
        { 'label': '全部', 'value': '' }, { 'label': '已设置', 'value': 1 },
      ],
      value: '',   //选择
      tableData: [{}],
      pageSize: 10,
      currentPage: 1,
      total: 1,

      pathId: '',
      isTrue: '',
    }
  },
  created() {
    this.pathId = sessionStorage.getItem('zi');
    this.projectId = sessionStorage.getItem('projectId');
    this.isTrue = sessionStorage.getItem('isTrue');
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to) {
      this.type = to.query.type
      // console.log(this.type)
    }
  },
  mounted() {
    this.getDataList();
    // this.operate();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {       //条纹变色
      if (rowIndex % 2 == 1) {
        return 'hui1';
      } else {
        return 'lv1';
      }
    },

    //判断是否可操作
    // operate(){
    //   this.$axios.get(`${this.baseURL}auth/authPermission/user/operation?id=${this.pathId}`).then(res => {
    //         console.log('是否可操作', res.data.data);
    //         this.isTrue = res.data.data;
    //       });
    // },

    getDataList() {
      this.$axios.get(`${this.baseURL}setttings/settingsDataClean/${this.currentPage}/${this.pageSize}?projectId=${this.projectId}&status=${this.status}&code=${this.searchTxt}`).then(res => {
        // console.log('数据处理列表',res);
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      })
    },
    changeValue() {
      this.status = this.value;
      this.currentPage = 1;
      this.getDataList();
    },
    go(id) {
      if (this.isTrue != "true") {
        // this.$message({
        //   message: "无权限，请联系管理员!",
        //   type: "warning"
        // });
        return;
      }

      // console.log('id', id)
      sessionStorage.setItem('handleId', id)
      // sessionStorage.setItem('handlePageId',this.currentPage)


      this.$router.push({
        name: 'secondaryDetail',
        params: {
          id: id
        }
      });
    },

    handleCurrentChange(val) {
      // console.log(val)
      this.currentPage = val
      this.getDataList();
    },


    resetData() { //重置
      this.handleCurrentChange(1)
      this.searchTxt = ''
      // this.getDataList()
    },

  }
}
</script>

<style scoped lang="scss">
.imgver {
  width: 60px;
  height: 55px;
  margin: 0 auto;
}

::v-deep .el-table__empty-text {
  margin-top: 10%;
}

.main {
  height: 100%;

  .pl24 {
    padding-left: 24px;
  }

  .mb48 {
    margin-bottom: 38px;
  }

  .el-table {
    .iconfont {
      font-size: 22px;
    }
  }

  .pageMain {
    margin-top: 35px;
  }

  .search {
    ::v-deep .el-input {
      width: 230px;
      height: 40px;
      margin-right: 20px;

      .el-icon-search:before {
        font-size: 22px;
        color: #2667DB;
      }

      .el-input__suffix {
        right: 12px;
      }

      .el-input__inner {
        padding-right: 32px;
      }
    }
  }
}

.sp {
  width: 94px !important;
}

.mlbtn {
  margin-left: 20px;
}

::v-deep .hui1 {
  background: #091D46 !important;
}

::v-deep .lv1 {
  background: #0C265A !important;
}
</style>